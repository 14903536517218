
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'close',
        'data-cm-role': 'close'
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'image',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), _createElement('div', { 'className': 'dialog-content' }, this.name === 'vehicle-dialog' ? [_createElement('div', {
            'className': 'dialog-body',
            'key': '2410'
        }, [this.DialogVehicleWidget(function () {
                function repeatSelects1(selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry4(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3532'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3734'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '39'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled
                            }, this.useNativeDropdown ? [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', '', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '7541'
                                    }, 'Popular Makes'),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '7544'
                                    }, 'All Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null,
                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'form-input cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'key': '2854'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', '', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                            ]) : null);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__dialog cm_DialogVehicleWidget' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this))
                ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
                    'onClick': this.selectVehicle,
                    'disabled': !this.allowToSetVehicle,
                    'role': 'button',
                    'tabIndex': this.allowToSetVehicle ? '0' : '-1'
                }, _createElement('span', {}, 'GO')), _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
                    'onClick': this.discardVehicle,
                    'disabled': !this.allowToDiscardVehicle,
                    'role': 'button',
                    'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
                }, [_createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'className': 'cm_icon cm_icon-reset',
                        'height': '16px',
                        'viewBox': '0 0 16 16',
                        'key': '66930'
                    }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))));
            }, {
                widgetName: 'undefined',
                items: undefined
            })])] : null, this.name === 'facet-dialog' ? [
        _createElement('div', {
            'className': 'dialog-title',
            'key': '85940'
        }, 'Filters'),
        [this.FacetPanel(function () {
                return _createElement('div', { 'className': 'cm_facet-panel__full-width dialog-body cm_FacetPanel' }, [[this.filterChips(function () {
                            function repeatChips1(chips, chipsIndex) {
                                return [chips(function () {
                                        function repeatI1_reviewChip(i, iIndex) {
                                            return _createElement('span', {
                                                'key': `${ this.field }|${ i }`,
                                                'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                            });
                                        }
                                        function scopeDollarInchMm1_rangeChip() {
                                            var dollar = [
                                                'price',
                                                'Price'
                                            ].includes(this.field) ? '$' : '';
                                            var inch = [].includes(this.field) ? '"' : '';
                                            var mm = [
                                                'wheel_bore',
                                                'wheel_offset'
                                            ].includes(this.field) ? 'mm' : '';
                                            return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, dollar, this.selectedRange[0], inch, mm, ' to\n    ', dollar, this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '630' }, dollar, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '738' }, dollar, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                    'className': 'cm_icon cm_icon-times',
                                                    'height': '20px',
                                                    'role': 'image',
                                                    'viewBox': '0 0 22 22',
                                                    'key': '8760'
                                                }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                        }
                                        return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'image',
                                                'viewBox': '0 0 22 22',
                                                'key': '2570'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                            'a',
                                            {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            },
                                            _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                            _map([
                                                0,
                                                1,
                                                2,
                                                3,
                                                4
                                            ], repeatI1_reviewChip.bind(this)),
                                            this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                        ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'image',
                                                'viewBox': '0 0 22 22',
                                                'key': '4900'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                    }, { count: undefined })];
                            }
                            return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                                'className': 'facettitle',
                                'tabIndex': '0'
                            }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                                'div',
                                {
                                    'role': 'list',
                                    'className': 'cmRepeater_chips'
                                },
                                _map(this.chips, repeatChips1.bind(this))
                            ]), _createElement('div', {
                                'className': 'button button--secondary cm_filter-chips_button',
                                'data-cm-role': 'start-over'
                            }, _createElement('span', {}, 'Start over', _createElement('span', {}))))));
                        }, {
                            widgetName: 'filter-chips',
                            items: undefined
                        })]], [this.facets(function () {
                        function repeatFacets1(facets, facetsIndex) {
                            return [facets(function () {
                                    function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues2(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues3(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), !this.isCustomRange ? [
                                                    _createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '1541'
                                                    }, _createElement('span', {}, this.value)),
                                                    _createElement('div', {
                                                        'className': 'facetentryval',
                                                        'key': '1543'
                                                    }, this.hitCount)
                                                ] : null, this.isCustomRange ? [_createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '3901'
                                                    }, this.finite ? _createElement('span', { 'key': '503' }, '$', this.range[0], ' - $', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '592' }, '$', this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '668' }, '$', this.range[1], ' and less') : null)] : null);
                                            }, { count: undefined })];
                                    }
                                    function scopeDollarInchMm4() {
                                        var dollar = [
                                            'price',
                                            'Price'
                                        ].includes(this.field) ? '$' : '';
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', {
                                            'className': 'cmTemplate_sliderFacet',
                                            'key': '12707'
                                        }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', dollar, this.selectedRange[0], inch, mm, ' to\n    ', dollar, this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, dollar, this.min, inch, mm), _createElement('div', { 'className': 'max' }, dollar, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                            return _createElement('div', {
                                                                'className': 'form-input cm_inputMin',
                                                                'placeholder': 'Min'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                    'className': 'separator',
                                                    'key': '345'
                                                }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                            return _createElement('div', {
                                                                'className': 'form-input cm_inputMax',
                                                                'placeholder': 'Max'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'button button--secondary',
                                                    'onClick': this.setCustomRange
                                                }, _createElement('span', {}, 'GO')));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]);
                                    }
                                    function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues6(Values, ValuesIndex) {
                                        return [Values(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                                        'className': 'facettitle',
                                        'data-cm-role': 'toggle-facet',
                                        'tabIndex': '0'
                                    }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle left',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '1650'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle down',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '4860'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                        'role': 'list'
                                    }, this.template === 'simpleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_simpleFacet',
                                        'key': '1006'
                                    }, this.showFilterInput ? _createElement('div', {
                                        'className': 'filter-input',
                                        'key': '1092'
                                    }, [this.filterInput(function () {
                                            return _createElement('div', {
                                                'className': 'input form-input cm_filterInput',
                                                'placeholder': 'Enter'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], this.inputNotEmpty ? _createElement('span', {
                                        'className': 'filter-input_clear-container',
                                        'onClick': this.clearInput,
                                        'key': '1422'
                                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '15911'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('div', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '3422'
                                    }, _createElement('a', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more',
                                        'tabIndex': '0'
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_selectFacet',
                                        'key': '3748'
                                    }, [this.select(function () {
                                            function repeatEntry1(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry3(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry4(entry, index) {
                                                return _createElement('div', {
                                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, this.showCheckboxes ? _createElement('input', {
                                                    'type': 'checkbox',
                                                    'readOnly': true,
                                                    'checked': entry.selected,
                                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                    'key': '3555'
                                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                    'className': 'cm_dropdown_hitCount',
                                                    'key': '3757'
                                                }, entry.hitCount) : null);
                                            }
                                            return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'key': '62'
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled
                                            }, this.useNativeDropdown ? [
                                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                    'value': '',
                                                    'key': 'null-option'
                                                }, '\n          ', '', this.title, '\n        ') : null,
                                                this.loading ? _createElement('option', {
                                                    'key': 'loading-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                    _createElement('option', {
                                                        'className': 'cm_option_title',
                                                        'disabled': true,
                                                        'key': '7771'
                                                    }, 'Popular Makes'),
                                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                    _createElement('option', {
                                                        'className': 'cm_option_title',
                                                        'disabled': true,
                                                        'key': '7774'
                                                    }, 'All Makes'),
                                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                                ] : null,
                                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                                'key': '_current',
                                                'value': '_current'
                                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                        return _createElement('div', { 'className': 'form-input cm_filterInput' });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'key': '2877'
                                                },
                                                !this.hideNullOption ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'onClick': () => this.onChange('')
                                                }, '\n      ', '', this.title, '\n    ') : null,
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_priceFacet',
                                        'key': '9663'
                                    }, !this.ranges.length ? [
                                        _createElement('div', { 'key': '97471' }, '$', this.selectedRange[0], ' to $', this.selectedRange[1]),
                                        '\n  ',
                                        this.slider,
                                        '\n  ',
                                        _createElement('div', {
                                            'className': 'cm_flex',
                                            'key': '97473'
                                        }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                                    ] : null, this.ranges.length ? [_createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cmRepeater_Values',
                                                'key': '100231'
                                            },
                                            _map(this.Values, repeatValues3.bind(this))
                                        ])] : null, [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'className': 'form-input cm_inputMin',
                                                        'placeholder': 'Min'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'className': 'form-input cm_inputMax',
                                                        'placeholder': 'Max'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('button', {
                                                'type': 'button',
                                                'className': 'button button--secondary',
                                                'onClick': this.setCustomRange
                                            }, _createElement('span', {}, 'GO')));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'sliderFacet' ? scopeDollarInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_reviewFacet',
                                        'key': '14667'
                                    }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues6.bind(this))
                                    ])) : null));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_facets' },
                            _map(this.facets, repeatFacets1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('div', {
            'className': 'dialog-footer',
            'key': '85944'
        }, _createElement('div', { 'className': 'dialog-footer-content' }, [this.showResultsButton(function () {
                return _createElement('button', {
                    'type': 'button',
                    'disabled': !this.totalHits,
                    'className': 'button button--primary cm_showResultsButton'
                }, this.totalHits ? [
                    'Show ',
                    this.totalHits,
                    ' Results'
                ] : null, !this.totalHits ? ['No Results'] : null);
            }, {
                widgetName: 'undefined',
                items: undefined
            })]))
    ] : null));
}
        export const componentNames = ["cm:filterInput","cm:DialogVehicleWidget","cm:filterChips","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:FacetPanel","cm:showResultsButton"]