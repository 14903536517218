//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-556:_5687,_836,_9872,_5576,_4823,_7640,_4132,_308;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-556')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-556', "_5687,_836,_9872,_5576,_4823,_7640,_4132,_308");
        }
      }catch (ex) {
        console.error(ex);
      }