//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-556:_6288,_9612,_7944,_5424,_1940,_8988,_4452,_7776;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-556')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-556', "_6288,_9612,_7944,_5424,_1940,_8988,_4452,_7776");
        }
      }catch (ex) {
        console.error(ex);
      }